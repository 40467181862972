import * as React from 'react';
import './HeroBanner.sass';
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Button, Fade, Slide, easing } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import looptech from '../../../helpers/looptech';
import urls from '../../../values/urls';
import { Link } from 'react-router-dom';

import { Context } from '../../../context/mainContext';
import langs from '../../../constant/langs';
interface ISlideInfo {
  id: number;
  tag: string;
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  image: string;
  path: string
}


export interface IHeroBannerProps {
}


let canChange = true;
export default function HeroBanner(props: IHeroBannerProps) {
  const [slideIndex, setSlideIndex] = React.useState<number>(0)
  const [sliderData, setSliderData] = React.useState<ISlideInfo[]>([])
  const infoRef = React.useRef<HTMLDivElement>(null)

  const {
    state: {
      language,
    },
  } = React.useContext<any>(Context);


  const calculateIndex = React.useCallback((value: number, isNext: boolean): number => {
    const dataLength = sliderData.length;
    const nextValue = isNext ? value + 1 : value - 1;
    if (nextValue < 0) return dataLength - 1;
    if (isNext) {
      if (nextValue > dataLength - 1) return 0;
    }
    return nextValue;
  },[sliderData])

  const handleChange = React.useCallback((isNext: boolean = true): void => {
    if (!canChange) return;
    canChange = false;
    setSlideIndex(val => calculateIndex(val, isNext))
  },[calculateIndex])

  const handleText = (text: string = ""): React.ReactElement => {
    const splitted = text.split(" ");
    const [first, ...rest] = splitted;
    return <span>{first}<br /> {rest.join(" ")}</span>
  }


  const validUrl = React.useCallback((url: string): string => {
    if (!url) return "";
    else if (url?.startsWith("/")) return url;
    else return `/${url}`;
  }, [])

  const SliderContent = React.useCallback(() => {
    const slide = sliderData[slideIndex % sliderData.length];
    const porductTitle = handleText(slide?.title);
    const image = validUrl(slide?.image);


    return (
      <SwiperSlide
        key={slide?.id}>
        <div className='slide'>
          <div ref={infoRef} className="info">
            <Slide easing={easing.easeInOut} direction="down" in={true} timeout={{ enter: 1200, exit: 1000 }} container={infoRef.current}>
              <div className="title">{porductTitle}</div>
            </Slide>
            <Slide
              onTransitionEnd={() => canChange = true}
              easing={easing.easeInOut} direction="right" in={true} timeout={{ enter: 1500, exit: 1000 }} container={infoRef.current}>
              <div className="description">{slide?.description}</div>
            </Slide>
            <Slide easing={easing.easeInOut} direction="right" in={true} timeout={{ enter: 1300, exit: 1000 }} container={infoRef.current}>
              <Link to={slide?.path} >
                <Button style={{ borderColor: 'white', color: 'white' }} className='button' variant="outlined" size='large'>{langs[language]["Discover now"]}</Button>
              </Link>
            </Slide>
          </div>
          <div className="slide-image-wrapper">
            <div className="slide-image">
              <Fade easing={easing.easeOut} in={true} timeout={{ enter: 800, exit: 1000 }}>
                <img src={looptech.image(image)} alt={slide?.title} />
              </Fade>
            </div>
          </div>
        </div>
      </SwiperSlide >
    )
  }, [slideIndex, sliderData, validUrl, language])


  const getHeroDatas = React.useCallback( async () => {
    try {
      const heroReq = await looptech.api().get(urls.heroBannerUrl(language));
      const data = await heroReq.data;
      setSliderData(data?.data || []);
    } catch (error) {
      console.log(error)
    }
  },[language])


  React.useEffect(() => {
    getHeroDatas();
  }, [getHeroDatas, language]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      handleChange(true)
    }, 5000);
    return () => clearInterval(interval);
  }, [handleChange]);



  return (
    <div className='hero-banner'>
      <div className="hero-slider">
        <SliderContent />
      </div>
      <div className="paginator">
        <Button onClick={() => handleChange(false)} variant='outlined' style={{ borderColor: 'white', color: 'white', marginRight: 5 }}>
          <ArrowBackIosNewIcon />
        </Button>
        <Button onClick={() => handleChange(true)} variant='outlined' style={{ borderColor: 'white', color: 'white' }}>
          <ArrowForwardIosIcon />
        </Button>
      </div>
    </div>
  );
}
