import * as React from 'react';
import './Layout.sass';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import looptech from '../../helpers/looptech';
import urls from '../../values/urls';
import { Context } from '../../context/mainContext';

export interface ILayoutProps {
  children: React.ReactNode;
}

interface ICallData {
  "site.call_phone": string;
}


export default function Layout(props: ILayoutProps) {
  const { setCallNumber, setContact, setSocial, state: {language} } = React.useContext<any>(Context)


  React.useEffect(() => {
    const getSocial = async () => {
      try {
        const req = await looptech.api().get(urls.social(language));
        const reqData = await req.data;
        const resData = reqData.data;
        setSocial({ data: resData })
      } catch (error) {
        console.log(error)
      }
    }

    const getContactData = async () => {
      try {
        const reqData = await looptech.api().get(urls.contact(language))
        const resData = await reqData.data;
        setContact({ data: resData.data });
      } catch (error) {
        console.log(error)
      }
    };



    const getCallNumber = async () => {
      try {
        const req = await looptech.api().get(urls.callPhone);
        const reqData = await req.data;
        const resData: ICallData = reqData.data
        setCallNumber({ number: resData['site.call_phone'] })
      } catch (error) {
        console.log(error)
      }
    };

    getSocial();
    getContactData();
    getCallNumber();
  }, [language])


  return (
    <div className='layout'>
      <Navbar />
      <div>{props.children}</div>
      <Footer />
    </div>
  );
}
