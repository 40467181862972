
import { useContext, useState } from 'react';
import styles from "./ItemDescription.module.css";
import langs from "../../constant/langs";
import { Context } from "../../context/mainContext";

const ItemDescription = ({ data = {} }) => {
  const [page, setPage] = useState(0);
  const { state: {language } } = useContext(Context);


  return (
    <>
      <hr />
      <div className={styles.container}>
        <div className={styles.btnContainer}>
          <button
            className={`${!page ? styles.active : ""}`}
            onClick={() => setPage(0)}
          >
            {langs[language]["Features"]}
          </button>
          <button
            className={`${page ? styles.active : ""}`}
            onClick={() => setPage(1)}
          >
            {langs[language]["Specifications"]}
          </button>
        </div>
        <hr />
        {!page ? (
          <div className={styles.description}>
            <p>{data?.features}</p>
          </div>
        ) : (
          <div
            style={{ justifyContent: "center", display: "flex" }}
            className={styles.container2}
          >
            <div    
              style={{
                borderCollapse: "collapse",
              }}
              dangerouslySetInnerHTML={{ __html: data?.specifications }}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default ItemDescription;
