import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/Home";
import ItemContentPage from "../pages/ItemContent";
import Solution from "../pages/Solution";
import Solutions from "../pages/Solutions/Solutions";
import Products from "../pages/Products/Products";
import Contact from "../pages/Contact";
import NotFound from "../pages/NotFound";
import { useContext, useEffect, useState } from "react";
import { Context } from "../context/mainContext";

const Root: React.FC = () => {
  const { setLanguage } = useContext<any>(Context);
  useEffect(() => {
    const storedLang = localStorage.getItem("@lang");
    if (storedLang) {
      setLanguage(storedLang);
    }
  }, []);


  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/products/:slug" element={<ItemContentPage />} />
      <Route path="/solutions/:slug?" element={<Solutions />} />
      <Route path="/solution/:slug" element={<Solution />} />
      <Route path="/categories/:product?" element={<Products />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Root;
