import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";




const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.includes("categories") || pathname.includes("solutions")) return;
        window?.scrollTo({ left: 0, top: 0 })
    }, [pathname]);

    return null;
}


export default memo(ScrollToTop);