import React, { memo } from "react";
import { IconType } from 'react-icons'

interface IProps {
    title: string;
    Icon: IconType;
    text1: string | undefined;
    text2?: string;
    iconSize?: number
};

const ContactInfo: React.FC<IProps> = ({ title, Icon, text1 = "", text2, iconSize = 20 }): React.ReactElement => {

    return (
        <div style={{ maxWidth: 250 }} >
            <div style={{ display: "flex", height: 40, alignItems: "center", gap: 10 }} >
                <Icon style={{ margin: 0 }} size={iconSize} color="#1dd4df" />
                <h3 >{title}</h3>
            </div>
            <p style={{ margin: 0, textAlign: "left", }} >{text1}</p>
            {!!text2 && <p style={{ marginTop: 10, textAlign: "start" }} >{text2}</p>}
        </div>
    );

};


export default memo(ContactInfo);