import axios, { AxiosInstance } from "axios";


interface IDefaultHeaders {
    Accept: "applicaton/json";
    "Content-type": "application/json";
};




class Looptech {
    private apiUrl: string | undefined;
    private headers: IDefaultHeaders;
    private storageUrl: string | undefined;

    constructor() {
        this.apiUrl = process.env.REACT_APP_API_KEY;
        this.storageUrl = process.env.REACT_APP_STORAGE;
        this.headers = {
            Accept: "applicaton/json",
            "Content-type": "application/json"
        }
    };


    api(headers: any | undefined = {}, signal?: AbortSignal): AxiosInstance {
        return axios.create({
            baseURL: this.apiUrl,
            headers: { ...this.headers, ...headers },
            signal
        });
    };

    image(url: string | undefined): string | undefined {
        if (!url) return;
        const joinStorage = (end: string): string => `${this.storageUrl}${end}`;
        let handledUrl;
        if (url.includes("[") && url.includes("]")) {
            const firstImage: string = JSON.parse(url)[0]
            if (!firstImage.startsWith("/")) handledUrl = `/${firstImage}`;
            else handledUrl = firstImage;
        } else {
            if (!url.startsWith("/")) handledUrl = `/${url}`;
            else handledUrl = url;
        }
        return joinStorage(handledUrl);
    }
};

const looptech = new Looptech();

export default looptech;