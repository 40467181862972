import { FormControl, FormHelperText, FormLabel, InputProps } from "@mui/material";
import React, { CSSProperties, memo, useMemo } from "react";
import contactStyles from "../pages/Contact/Contact.module.sass";




interface IInputProps {
    value: string;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    type?: React.HTMLInputTypeAttribute;
    style?: CSSProperties;
    name?: string;
    placeholder?: string;
    error?: string;
    props?: InputProps
    divStyle?: CSSProperties;
    textarea?: boolean
    areaClassName?: any;
    divClassName?: any
}


const defaultStyle: CSSProperties = {
    minWidth: 160,
    minHeight: 30,
    borderRadius: 1,
    borderWidth: 0.5,
    paddingInline: 20,
};
const Input: React.FC<IInputProps> = ({ textarea = false, value = "", divClassName = "", areaClassName = "", style, divStyle, onChange, type = "text", name, placeholder = "", error = "", ...props }): React.ReactElement => {

    const handleStyle = (styles: CSSProperties | undefined): CSSProperties => {
        return { ...defaultStyle, ...styles };
    }

    const inputStyle = useMemo(() => handleStyle(style), [style]);

    return (
        <div
            className={divClassName}
            style={divStyle} >
            <FormControl >
                <FormLabel style={{ display: "block" }} >{placeholder}</FormLabel>
                {textarea ?
                    <textarea
                        className={areaClassName}
                        style={{ ...inputStyle, width: "100%", minHeight: 80 }}
                        value={value}
                        name={name}
                        placeholder={placeholder}
                        {...props}
                        onChange={onChange}
                    >
                    </textarea>
                    :
                    <input
                        className={contactStyles["input"]}
                        style={inputStyle}
                        value={value}
                        onChange={onChange}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        {...props}
                    />}
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </div>
    );
};

export default memo(Input);