import React, { useCallback, useContext } from "react";
import looptech from "../../helpers/looptech";
import CustomButton from "../UI/CustomButton";
import styles from "./index.module.css";
import { Context } from "../../context/mainContext";
import langs from "../../constant/langs";

interface IItemData {
  image: string;
  title: string;
  description: string;
  category: any
};

interface IItemProps {
  data: IItemData;
}

const ItemContent: React.FC<IItemProps> = ({ data }) => {
  const { state: { callNumber, language } } = useContext<any>(Context);

  const call = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (callNumber) window.open(`tel:${callNumber}`, "_self");
  }, [callNumber]);



  return (

    <div className={styles.container}>

      <div className={styles.imgContainer}>
        <img
          src={looptech.image(data.image)}
          style={{ objectFit: "contain" }}
          alt={data.title}
        />
      </div>
      <div>
        <h2>{data.title}</h2>
        {/* <h4>Niyazi oglu</h4> */}
        {/* <p>sku: senanqulu</p> */}
        <hr />
        <p>{data.description}</p>
        <CustomButton
          buttonStyle={{ marginBottom: 60, width: 170, height: 40 }}
          variant="contained"
          hoverColor="white"
          endTextColor="black"
          startTextColor="white"
          isCall={true}
          onClick={call}
        >
          {langs[language]["Call"]}
        </CustomButton>
        <hr />
        <p className={styles.categoryContainer}>
          {langs[language]["Category"]}:{" "}
          <span className={styles.categoryName}>{data?.category?.name}</span>
        </p>
      </div>
    </div>
  );
};

export default ItemContent;
