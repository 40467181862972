import * as React from "react";
import { Drawer, IconButton, List, ListItemButton, ListItemText, Collapse, easing, SxProps } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { IItemButton } from "../../pages/Products/Products";
import { Context } from "../../context/mainContext";
import { Link, useNavigate } from "react-router-dom";

export interface ILeftDrawerProps {
    open: boolean;
    onClose: () => void;
    drawerToggle: React.Dispatch<React.SetStateAction<boolean>>
}

export const sxProps: SxProps = {
    bgcolor: "#eee",
    '&.Mui-selected': {
        color: "white",
        backgroundColor: "black"
    },
    "&.Mui-selected:hover": {
        color: "white",
        backgroundColor: "black"
    },
    ":hover": {
        color: "black",
        backgroundColor: "#ccc"
    }
};

const contentStyle = {
    fontSize: 18,
    fontWeight: 500,
    display: "block",
    margin: 20
}
export default function LeftDrawer(props: ILeftDrawerProps) {
    const [open, setOpen] = React.useState<number>(0);
    const { changeLayoutHeader, state: { categories } } = React.useContext<any>(Context)
    const navigate = useNavigate();



    const handleNavigate = React.useCallback((screen: string, title: string) => {
        props.drawerToggle(() => false);
        changeLayoutHeader({ title })
        navigate(`/categories/${screen}`)
    }, [navigate, props, changeLayoutHeader])


    const handleOpen = (itemId: number) => {
        setOpen((prev: number) => {
            if (prev === itemId) return 0
            return itemId
        })
    };

    const handleExpand = React.useCallback((e: React.SyntheticEvent, id: number) => {
        e.stopPropagation();
        handleOpen(id)
    }, [])



    const CollapsedItem = React.useCallback(({ isCollapsed = false, onClick, collapsedItems }: any) => {
        return (
            <Collapse in={isCollapsed} easing={easing.easeInOut} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {collapsedItems.map(({ name, id, slug }: any) => {
                        return (
                            <ListItemButton
                                key={id}
                                id={`${id}`}
                                disableRipple
                                // className={productStyles["category-button"]}
                                onClick={() => onClick(slug, name)}
                                sx={{ pl: 4, ...sxProps }}>
                                <ListItemText primary={name} />
                            </ListItemButton>
                        )
                    })}
                </List>
            </Collapse>
        )
    }, []);




    const ItemButton = React.useCallback(({ buttonText, onCategoryClick, onSubCategoryClick, isCollapsible, collapsedItems = [], itemId, active }: IItemButton): React.ReactElement => {
        return (
            <>
                <ListItemButton
                    disableRipple
                    id={`${itemId}`}
                    onClick={() => onCategoryClick(buttonText)}
                    sx={{ pl: 2, ...sxProps }}>
                    <ListItemText primary={buttonText} />
                    {active ?
                        <ExpandLess onClick={(e) => handleExpand(e, itemId)} />
                        :
                        <ExpandMore onClick={(e) => handleExpand(e, itemId)} />
                    }
                </ListItemButton>
                {isCollapsible && <CollapsedItem
                    isCollapsed={active}
                    onClick={(id: string, title: string) => onSubCategoryClick(id, title)}
                    collapsedItems={collapsedItems}
                />}
            </>
        )
    }, [handleExpand, CollapsedItem]);


    const Content = ({ to, title }: any) => {
        return (
            <Link
                onClick={() => props.drawerToggle(() => false)}
                style={{
                    color: "inherit",
                    textDecoration: "none"
                }} to={to} >
                <span style={contentStyle} >{title}</span>
            </Link>
        )
    }

    return (
        <Drawer
            anchor={"left"}
            open={props.open}
            onClose={props.onClose}
            disableScrollLock
        >
            <div className="left-drawer">
                <div className="drawer-header">
                    <div className="drawer-header-title">Main Menu</div>
                    <IconButton
                        onClick={props.onClose}
                        className="drawer-header-close-button"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <hr />
                <div className="drawer-content">
                    <div style={{
                        gap: 20
                    }} >

                        <Content title={"HOME"} to={"/"} />
                        <Content title={"SOLUTIONS"} to={"/solutions"} />
                        <Content title={"CONTACT"} to={"/contact"} />
                        <Content title={"ALL PRODUCTS"} to={"/categories"} />
                    </div>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {categories?.map((item: any) => {
                            return (
                                <ItemButton
                                    key={item.id}
                                    onCategoryClick={(title: string) => handleNavigate(item.slug, title)}
                                    onSubCategoryClick={(link: string, title: string) => handleNavigate(link, title)}
                                    buttonText={item.name}
                                    isCollapsible={true}
                                    itemId={item.id}
                                    active={open === item.id}
                                    collapsedItems={item.children}

                                />
                            )
                        })}

                    </List>
                </div>
            </div>
        </Drawer>
    );
}
