import React, { useEffect, useState, useReducer } from "react";
import stadiumImage from "../../../assets/images/stadium.jpg";
import { Container } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper/types";
import { Pagination, Autoplay } from "swiper";
import CustomButton from "../../../components/UI/CustomButton";
import styles from "./ProductPromotion.module.sass";
import looptech from "../../../helpers/looptech";
import urls from "../../../values/urls";
import { Link } from "react-router-dom";
import { Context } from "../../../context/mainContext";
import { ILanguage } from "../../../constant/types";
import langs from "../../../constant/langs";

interface IPoster {
  image: string;
  title: string;
  buttonText: string;
  buttonLink: string;
  productColor: string;
  price: number;
  slug: string;
}

interface IProduct {
  image: string;
  title: string;
  description: string;
  special?: true;
  link?: string;
  price?: number;
  name?: string;
}

const reducer = (state: IInitialAllData, action: any) => {
  return { ...state, ...action };
};

interface IIndexBlock {
  id: number;
  title: string;
  description: string;
  image: string;
  path: string | null;
}

interface IInitialAllData {
  sliderOne: IPoster[];
  sliderTwo: IPoster[];
  topProducts: IProduct[];
  categories: IProduct[];
  indexBlock: IIndexBlock[];
}

const initialAllData: IInitialAllData = {
  sliderOne: [],
  sliderTwo: [],
  topProducts: [],
  categories: [],
  indexBlock: [],
};
const allDataKeys = Object.keys(
  initialAllData
) as (keyof typeof initialAllData)[];
type IAllDataKeys = (typeof allDataKeys)[number];

type IGetDataParams = "slider_1" | "slider_2" | "top_product";
type IGetCategoriesParams = "children";
interface IResData {
  data: any[];
}

export const getProductData = (param: IGetDataParams, lng: ILanguage) =>
  looptech.api().get(`${urls.mainProductsUrl(lng)}&${param}=1`);
export const getCategoriesData = (
  lng: ILanguage,
  param?: IGetCategoriesParams
) => looptech.api().get(`${urls.mainCategoriesUrl(lng)}&${param}=1`);
export const getIndexBlockData = (lng: ILanguage) =>
  looptech.api().get(`index-blocks?lang=${lng}`);

export default function ProductPromotion() {
  const [firstPosterSlideIndex, setFirstPosterSlideIndex] = useState<number>(0);
  const [secondPosterSlideIndex, setSecondPosterSlideIndex] =
    useState<number>(0);
  const [firstPosterSwiper, setFirstPosterSwiper] = useState<SwiperType>();
  const [secondPosterSwiper, setSecondPosterSwiper] = useState<SwiperType>();
  const [allData, dispatch]: [IInitialAllData, React.Dispatch<any>] =
    useReducer(reducer, initialAllData);

  // call useContext language
  const {
    state: { language },
  } = React.useContext<any>(Context);

  const getAllData = async (): Promise<void> => {
    try {
      const allData = await Promise.allSettled([
        getProductData("slider_1", language),
        getProductData("slider_2", language),
        getProductData("top_product", language),
        getCategoriesData(language),
        getIndexBlockData(language),
      ]);
      allData.forEach(async (resData, index) => {
        if (resData.status === "fulfilled") {
          const data: IResData = await resData.value.data;
          const fieldName: IAllDataKeys = allDataKeys[index];
          const successData: any[] = data.data;
          if (fieldName === "topProducts") {
            successData.push({
              title: "PROFESSIONAL AUDIO",
              description:
                "ITC professional manufacturer for audiovisual & lighting products with strongest R&D and produce ability, provide complete solutions for what you need!",
              special: true,
              path: "/categories/professional-audio",
              image: require("../../../assets/images/Static-special.png"),
              id: Math.random(),
            });
          }
          dispatch({ [fieldName]: successData });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log("language", language);

  useEffect(() => {
    getAllData();
  }, [language]);

  return (
    <div className={styles["product-promotion"]}>
      <Container>
        <div className={styles["categories"]}>
          <div className={styles["title"]}>{langs[language]["Products"]}</div>
          <div className={styles["category-list"]}>
            <Swiper
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              className={styles["products-swiper"]}
              modules={[Pagination]}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                600: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                800: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1100: {
                  slidesPerView: 7,
                  spaceBetween: 30,
                },
              }}
            >
              {allData.categories.map((category: any, index) => {
                return (
                  <SwiperSlide key={category.id}>
                    <Link
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      to={`/categories/${category.slug}`}
                    >
                      <div className={styles["category-item"]}>
                        <div className={styles["category-image-wrapper"]}>
                          <img src={looptech.image(category.image)} alt="" />
                        </div>
                        <div className={styles["category-title"]}>
                          {category.name}
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </Container>
      <div className={styles["posters"]}>
        <div className={`${styles["poster-item"]} ${styles["raw-poster"]}`}>
          <img
            src={looptech.image(allData.indexBlock[0]?.image)}
            alt={allData.indexBlock[0]?.title}
          />
          <div className={styles["title"]}>{allData.indexBlock[0]?.title}</div>
          <div className={styles["button-wrapper"]}>
            <Link to={allData.indexBlock[0]?.path || ""}>
              <CustomButton
                variant="contained"
                startBgColor="white"
                startTextColor="black"
                endTextColor="white"
                hoverColor="black"
              >
                {langs[language]["Details"]}
              </CustomButton>
            </Link>
          </div>
        </div>

        <div className={`${styles["poster-item"]} ${styles["slide-poster"]}`}>
          <Swiper
            navigation
            speed={1400}
            autoplay={{ delay: 1500, disableOnInteraction: false }}
            className={styles["poster-swiper"]}
            preventInteractionOnTransition={false}
            modules={[Pagination, Autoplay]}
            onSwiper={(swiper) => setFirstPosterSwiper(swiper)}
            onSlideChange={(swiper) =>
              setFirstPosterSlideIndex(swiper.activeIndex)
            }
            slidesPerView={1}
          >
            {allData.sliderOne.map((poster: any, index) => {
              return (
                <SwiperSlide key={poster.id}>
                  <div className={styles["poster-item"]}>
                    <div className={styles["poster-image-wrapper"]}>
                      <img src={looptech.image(poster.image)} alt="" />
                    </div>
                    <div className={styles["poster-title"]}>{poster.title}</div>
                    <div className={styles["button-wrapper"]}>
                      <Link to={`/products/${poster.slug}`}>
                        <CustomButton hoverColor="#cf1b22" variant="contained">
                          {langs[language]["Show now"]}
                        </CustomButton>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className={styles["pagination"]}>
            {allData.sliderOne.map((_, index) => {
              const className =
                index === firstPosterSlideIndex
                  ? `${styles["page"]} ${styles["active"]}`
                  : styles["page"];
              const clickHandler = () => firstPosterSwiper?.slideTo(index);
              return (
                <div
                  className={className}
                  onClick={clickHandler}
                  style={{ backgroundColor: "gray" }}
                  key={`first ${index}`}
                ></div>
              );
            })}
          </div>
        </div>

        <div className={`${styles["poster-item"]} ${styles["slide-poster"]}`}>
          <Swiper
            navigation
            speed={1400}
            autoplay={{ delay: 1500, disableOnInteraction: false }}
            className={styles["poster-swiper"]}
            modules={[Pagination, Autoplay]}
            onSwiper={(swiper) => setSecondPosterSwiper(swiper)}
            onSlideChange={(swiper) =>
              setSecondPosterSlideIndex(swiper.activeIndex)
            }
            slidesPerView={1}
          >
            {allData.sliderTwo.map((poster: any, index) => {
              return (
                <SwiperSlide key={poster.id}>
                  <div className={styles["poster-item"]}>
                    <div className={styles["poster-image-wrapper"]}>
                      <img
                        src={looptech.image(poster.image)}
                        alt={poster.title}
                      />
                    </div>
                    <div className={styles["poster-title"]}>{poster.title}</div>
                    <div className={styles["button-wrapper"]}>
                      <Link to={`/products/${poster.slug}`}>
                        <CustomButton hoverColor="#cf1b22" variant="contained">
                          {langs[language]["Show now"]}
                        </CustomButton>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className={styles["pagination"]}>
            {allData.sliderTwo.map((_, index) => {
              const className =
                index === secondPosterSlideIndex
                  ? `${styles["page"]} ${styles["active"]}`
                  : styles["page"];
              const clickHandler = () => secondPosterSwiper?.slideTo(index);
              return (
                <div
                  className={className}
                  onClick={clickHandler}
                  style={{ backgroundColor: "gray" }}
                  key={`second ${index}`}
                />
              );
            })}
          </div>
        </div>
        <div className={`${styles["poster-item"]} ${styles["raw-poster"]}`}>
          <img
            src={looptech.image(allData.indexBlock[1]?.image)}
            alt={allData.indexBlock[1]?.title}
          />
          <div className={styles["title"]}>{allData.indexBlock[1]?.title}</div>
          <div className={styles["button-wrapper"]}>
            <Link to={allData.indexBlock[1]?.path || ""}>
              <CustomButton
                variant="contained"
                startBgColor="white"
                startTextColor="black"
                endTextColor="white"
                hoverColor="black"
              >
                {langs[language]["Details"]}
              </CustomButton>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles["banner-section"]}>
        <div className={styles["banner-title"]}>
          {langs[language]["Solutions"]}
        </div>
        <div
          className={styles["banner"]}
          style={{ backgroundImage: `url("${stadiumImage}")` }}
        >
          <div className={styles["text"]}>
            {langs[language]["Professional sound systems for arenas"]
              .toUpperCase()
              .split(" ")
              .map((word, index) => {
                return <span key={`word ${index}`}>{word}</span>;
              })}
          </div>
          <Link
            style={{
              color: "inherit",
              textDecoration: "none",
            }}
            to={"/solutions"}
          >
            <CustomButton
              variant="contained"
              startBgColor="white"
              startTextColor="black"
              endTextColor="white"
              hoverColor="black"
            >
              {langs[language]["Solutions"]}
            </CustomButton>
          </Link>
        </div>
      </div>

      <div className={styles["top-products"]}>
        <div className={styles["section-title"]}>
          {langs[language]["Top products"]}
        </div>
        <Container>
          <div className={styles["products"]}>
            {allData.topProducts.map((product: any) => {
              if (product.special) {
                return (
                  <div
                    className={`${styles["product"]} ${styles["special-product"]}`}
                    key={product.id}
                  >
                    <div style={{ flex: "1 0 50%" }} className={styles["info"]}>
                      <div
                        style={{
                          wordWrap: "break-word",
                          fontSize: 26,
                          whiteSpace: "pre-wrap",
                        }}
                        className={styles["title"]}
                      >
                        {product.title}
                      </div>
                      <div
                        style={{ fontSize: 12, marginTop: "1rem" }}
                        className={styles["description"]}
                      >
                        {product.description}
                      </div>
                      <Link
                        className={styles["title"]}
                        style={{
                          wordWrap: "break-word",
                          fontSize: 18,
                          whiteSpace: "pre-wrap",
                          color: "white",
                          textDecoration: "none",
                        }}
                        to={product.path}
                      >
                        {langs[language]["More details"]}
                      </Link>
                    </div>
                    <div
                      style={{ flex: "1 0 50%" }}
                      className={styles["image-wrapper"]}
                    >
                      <img src={product.image} alt={product.title} />
                    </div>
                  </div>
                );
              } else {
                return (
                  <Link
                    key={product.id}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                    to={`/products/${product.slug}`}
                  >
                    <div
                      className={`${styles["product"]} ${styles["simple-product"]}`}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          border: "0.2px solid #aaaaaa",
                        }}
                        className={styles["image-wrapper"]}
                      >
                        <img
                          src={looptech.image(product.image)}
                          alt={product.title}
                        />
                      </div>
                      <div className={styles["title"]}>{product.title}</div>
                      <div className={styles["description"]}>
                        {product.description}
                      </div>
                      <div className={styles["button-wrapper"]}>
                        <Link to={`/products/${product.slug}`}>
                          <CustomButton
                            variant="contained"
                            hoverColor="#cf1b22"
                          >
                            {langs[language]["Show now"]}
                          </CustomButton>
                        </Link>
                      </div>
                    </div>
                  </Link>
                );
              }
            })}
          </div>
        </Container>
        {/* <div className={styles["add-product-wrapper"]}>
					<CustomButton
						variant="contained"
						startBgColor="white"
						startTextColor="black"
						endTextColor="white"
						hoverColor="black"
						buttonStyle={{ width: 140 }}
					>
						Add Product
					</CustomButton>
				</div> */}
      </div>
    </div>
  );
}
