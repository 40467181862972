import { Container } from "@mui/material";
import React, { useContext, useReducer, useState } from "react";
import styles from "../Solutions/index.module.css";
import ContactInfo from "../../components/Contact/ContactInfo";
import { HiOutlineMail } from 'react-icons/hi';
import { FiPhone } from 'react-icons/fi';
import { TfiLocationPin } from 'react-icons/tfi';
import { BsClock } from 'react-icons/bs';

import ContactBG from "../../assets/images/contact-bg.jpg";

import Input from "../../components/Input";
import contactStyles from "./Contact.module.sass";
import CustomButton from "../../components/UI/CustomButton";
import { Context } from "../../context/mainContext";
import looptech from "../../helpers/looptech";
import urls from "../../values/urls";
import { ILanguage } from "../../constant/types";
import langs from "../../constant/langs";


export interface IContact {
    "site.addresses": string | undefined;
    "site.emails": string | undefined;
    "site.opening_hours": string | undefined;
    "site.phones": string | undefined;
}

type InputChange = "name" | "email" | "message";

interface IInitialState {
    name: string;
    email: string;
    message: string;
}

const initialState: IInitialState = {
    name: "",
    email: "",
    message: ""
};

const checker = (data: IInitialState | any) => {
    for (const key in data) {
        const value = data[key];
        if (!value) return key;
    }
    return false;
}

const mainReducer = (state: any, action: any): any => {
    if (action.type === "reset") return initialState;
    return { ...state, ...action };
}


interface IState {
    state: {
        contacts: IContact
        language: ILanguage
    }
}

let debounce: NodeJS.Timeout;
const Contact: React.FC = (): React.ReactElement => {
    const [userMessage, dispatch] = useReducer(mainReducer, initialState);
    const { state: { contacts, language },  }: IState = useContext<any>(Context);

    const [error, setError] = useState<string>("");
    const [sucess, setSuccess] = useState<boolean>(false)


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: InputChange,) => {
        dispatch({ [field]: e.target.value });
    };

    const handleError = (err: string) => {
        const index = err.indexOf("(");
        console.log(err.slice(0, index));
        setError(err.slice(0, index));
    }

    const postData = () => {
        clearTimeout(debounce);
        debounce = setTimeout(async () => {
            setError("")
            const checked = checker(userMessage);
            if (checked) return setError(`The ${checked} field is required!`);
            try {
                const req = await looptech.api().post(urls.contact(language), userMessage);
                const reqData = await req.data;
                if (!reqData.success) console.log(reqData);
                setError("");
                setSuccess(true)
                dispatch({ type: "reset" })
            } catch (error: any) {
                handleError(error.response.data.message)
            };
        }, 500);

    };



    return (
        <div  >
            <div
                style={{
                    backgroundImage: `url(${ContactBG})`
                }}
                className={styles.banner}>
                <Container>
                    <p
                        style={{
                            textShadow: "5px 5px 10px black"
                        }}
                    >{langs[language]["Contact"]}</p>
                </Container>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }} >
                <div className={contactStyles["contacts"]} >
                    <div  >
                        <h2 style={{ textAlign: "left", fontSize: 20 }} >
                            {langs[language]["Don't hesitate to contact us if you need help"]}
                            {/* DON’T HESITATE TO CONTACT US IF YOU NEED HELP. */}
                        </h2>
                    </div>
                    <div
                        className={contactStyles["info-container"]}
                        style={{ display: "flex", maxWidth: 520, gap: 20, flexWrap: "wrap" }} >
                        <ContactInfo
                            key={contacts?.["site.emails"]}
                            title={langs[language]["Email"]}
                            Icon={HiOutlineMail}
                            text1={contacts?.["site.emails"]}
                        />
                        <ContactInfo
                            key={contacts?.["site.addresses"]}
                            title={langs[language]["Addresses"]}
                            Icon={TfiLocationPin}
                            text1={contacts?.["site.addresses"]}
                        />
                        <ContactInfo
                            key={contacts?.["site.opening_hours"]}
                            title={langs[language]["Opening Hours"]}
                            Icon={BsClock}
                            text1={contacts?.["site.opening_hours"]}
                        />
                        <ContactInfo
                            key={contacts?.["site.phones"]}
                            title={langs[language]["Phones"]}
                            Icon={FiPhone}
                            text1={contacts?.["site.phones"]}
                        />
                    </div >
                </div>
                <div
                    className={contactStyles["submit"]}
                    style={{ marginBottom: 30, }} >
                    <div style={{ textAlign: "center" }} >
                        <h1>{langs[language]["Got Any Questions?"]}</h1>
                        <h5>{langs[language]["Use the form below to get in touch with the sales team"]}</h5>
                    </div>
                    <>
                        <div
                            className={contactStyles["inputs"]}
                            style={{ display: "flex", flexWrap: "wrap", gap: 20, }} >
                            <Input
                                onChange={(e) => handleInputChange(e, "name")}
                                value={userMessage.name}
                                placeholder={langs[language]["Name"]}
                            />
                            <Input
                                onChange={(e) => handleInputChange(e, "email")}
                                value={userMessage.email}
                                placeholder={langs[language]["Email"]}
                            />
                        </div>
                        <Input
                            divClassName={contactStyles["textarea"]}
                            divStyle={{ marginTop: 20 }}
                            onChange={(e) => handleInputChange(e, "message")}
                            value={userMessage.message}
                            style={{ resize: "none", }}
                            placeholder={langs[language]["Message"]}
                            textarea
                        />

                    </>
                    {sucess || <CustomButton
                        variant="outlined"
                        startBgColor="black"
                        startTextColor="white"
                        endTextColor="black"
                        hoverColor="white"
                        onClick={postData}
                        buttonStyle={{
                            borderWidth: 0,
                            boxShadow: "0px 0px 10px black",
                            width: 150,
                            height: 40,
                            marginTop: 20
                        }}
                    >
                        {langs[language]["submit"]}
                    </CustomButton>}
                    {!!error.length && <span style={{
                        fontSize: 18,
                        marginTop: 10,
                        color: "red",
                        display: "block"
                    }} >{error}</span>}
                    {
                        sucess && <span style={{
                            fontSize: 18,
                            marginTop: 10,
                            color: "green",
                            display: "block"
                        }} >Message sent successfully!</span>
                    }
                </div>
            </div>
        </div>
    )

};


export default Contact;