import { Container } from "@mui/material";
import styles from "./ItemContentRelated.module.sass";
import { memo, useContext } from "react";
import looptech from "../../helpers/looptech";
import { Link } from "react-router-dom";
import langs from "../../constant/langs";
import { Context } from "../../context/mainContext";



const RelatedProducts = ({ data = [] }) => {
  const { state: { language } } = useContext<any>(Context);

  return (
    <div className={`${styles["top-products"]}`}>
      <Container>
        {!!data.length && <div
          className={styles["section-title"]}
          style={{
            textTransform: "uppercase",
            color: "var(--accent)",
            fontWeight: 500,
            marginBottom: "43px",
            textAlign: "center"
          }}
        >
          {langs[language]["Related Products"]}
        </div>}
        <div className={styles["products"]} >
          {data?.map((item: any) => {
            return (
              <Link
                key={item.id}
                to={`/products/${item.slug}`} >
                <div

                  className={`${styles["product"]} ${styles["simple-product"]}`}
                >
                  <div style={{ backgroundColor: "white" }} className={styles["image-wrapper"]}>
                    <img
                      src={looptech.image(item.image)}
                      alt={item.title}
                    />
                  </div>
                  <div style={{ marginTop: 10 }} className={styles["title"]}>{item.title}</div>
                  <div className={styles["description"]}>{item.description}</div>
                </div>
              </Link>
            )
          })}
        </div>
      </Container >
    </div >
  );
};
export default memo(RelatedProducts);
