import React, { useContext, useEffect, useReducer } from "react";
import ItemContent from "../../components/ItemContent";
import ItemDescription from "../../components/ItemContent/ItemDescription";
import RelatedProducts from "../../components/ItemContent/RelatedProducts";
import looptech from "../../helpers/looptech";
import urls from "../../values/urls";
import { useParams } from "react-router-dom";
import { Context } from "../../context/mainContext";

interface IInitialAllData {
  data: any,
  related: any
}

const initialAllData: IInitialAllData = {
  data: [],
  related: []
}

const reducer = (state: any, action: any) => {
  return { ...state, ...action };
}

type IAllDataKeys = "data" | "related";

const allDataKeys: IAllDataKeys[] = ["data", "related"];
const ItemContentPage: React.FC = (): React.ReactElement => {
  const [allData, dispatch]: [IInitialAllData, React.Dispatch<any>] = useReducer(reducer, initialAllData);
  const { slug } = useParams();

  const {state:{language}} = useContext<any>(Context)


  useEffect(() => {
    const getRequests = () => {
      const reqUrls = urls.singleProduct(slug, language)
      const productReq = looptech.api().get(reqUrls.product);
      const relatedReq = looptech.api().get(reqUrls.related);
      return [productReq, relatedReq];
    };

    const getAllData = async (): Promise<void> => {
      try {
        const reqData = await Promise.allSettled(getRequests())
        reqData.forEach(async (resData, index) => {
          if (resData.status === "fulfilled") {
            const data = await resData.value.data;
            dispatch({ [allDataKeys[index]]: data.data });
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
    getAllData()
  }, [slug, language]);

  return (
    <>
      <ItemContent data={allData.data} />
      <ItemDescription data={allData.data} />
      <RelatedProducts data={allData.related} />
    </>
  );
};

export default ItemContentPage;
