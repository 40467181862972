import * as React from 'react';
// import fade from mui
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import langs from '../../constant/langs';
import { Context } from '../../context/mainContext';

export interface IHeaderSearchProps {
  open: boolean;
  closeSearch: () => void;
}

const HeaderSearch = (props: IHeaderSearchProps) => {
  const [search, setSearch] = React.useState<string>("");
  const inputRef: any = React.useRef();
  const { state: { language } } = React.useContext<any>(Context);


  React.useEffect(() => {
    if (props.open) inputRef.current.focus();
    else inputRef.current.blur();
  }, [props.open])



  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);


  const handleKeyDownEvent = React.useCallback((e: React.KeyboardEvent): boolean | void => {
    if (!search.trim()) return;
    const pressedKey = e.key;
    if (pressedKey !== "Enter") return false;
    window.location.href = `/categories?search=${search.trim()}`
  }, [search])


  return (
    <Fade in={props.open}>
      <div className='header-search'>
        <input 
          onKeyDown={handleKeyDownEvent}
          onChange={handleChange}
          value={search}
          ref={inputRef}
          autoFocus={true}
          type="text"
          name='search'
          placeholder={langs[language]["Search"] + '...'} 
          className='search-input' />
        <div>
          <IconButton onClick={props.closeSearch} className='close-button'><CloseIcon /></IconButton>
        </div>
      </div>
    </Fade>
  );
};


export default HeaderSearch;
