import "./Home.sass"
import HeroBanner from './HeroBanner/HeroBanner';
import ProductPromotion from './ProductPromotion/ProductPromotion';
export interface IHomePageProps {
}

export default function HomePage(props: IHomePageProps) {
    return (
        <div>
            <HeroBanner />
            <ProductPromotion />
        </div>
    );
}
