import * as React from 'react';
import './Footer.sass';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import logoImage from '../../assets/itc-logo-white.png';
import { Container } from '@mui/material';
import { Context } from '../../context/mainContext';
import { IContact } from '../../pages/Contact';
import looptech from '../../helpers/looptech';
import urls from '../../values/urls';
import { Link } from 'react-router-dom';
import langs from '../../constant/langs';
import { ILanguage } from '../../constant/types';




interface ISocial {
  "site.facebook": string | undefined;
  "site.instagram": string | undefined;
  "site.youtube": string | undefined;
  "site.linkedin": string | undefined;
  "site.twitter": string | undefined;
}

interface IState {
  state: {
    callNumber: string;
    contacts: IContact;
    social: ISocial;
    language: ILanguage
  }
}


const pointerStyle = { cursor: "pointer" };
export default function Footer() {
  const { state: { callNumber, contacts, social, language } }: IState = React.useContext<any>(Context)


  const clickHandler = (link: string | undefined) => {
    if (link) window.open(link, "_blank");
  };


  return (
    <div className='footer'>
      <Container style={{ margin: 0, padding: 0, minWidth: "100%" }} >
        <div className="header">
          <div className="image-wrapper">
            <div className="brand">
              <Link to={"/"} >
                <img style={{ height: 40 }} src={logoImage} alt="" />
              </Link>
            </div>
          </div>
          <div className="data">
            <div className="title">{langs[language]["Call us"]}</div>
            <a style={{ cursor: "pointer" }} onClick={() => window.open(`tel:${callNumber}`, "_self")} className="content">{callNumber}</a>
          </div>
          <div className="data">
            <div className="title">{langs[language]["Opening Hours"]}</div>
            <div className="content">{contacts['site.opening_hours']}</div>
          </div>
          <div className="data">
            <div className="title">{langs[language]["Address office"]}</div>
            <div className="content">{contacts['site.addresses']}</div>
          </div>
          <div className="data">
            <div className="title">{langs[language]["Follow us"]}</div>
            <div className="social">
              <YouTubeIcon style={pointerStyle} onClick={() => clickHandler(social['site.youtube'])} />
              <FacebookIcon style={pointerStyle} onClick={() => clickHandler(social['site.facebook'])} />
              <InstagramIcon style={pointerStyle} onClick={() => clickHandler(social['site.instagram'])} />
              <TwitterIcon style={pointerStyle} onClick={() => clickHandler(social['site.twitter'])} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
