
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../context/mainContext';
import { getCategoriesData } from '../../pages/Home/ProductPromotion/ProductPromotion';
import looptech from '../../helpers/looptech';
import langs from '../../constant/langs';

export interface iCatalog {
  image: string;
  title: string;
  id: number;
  subCategories: { title: string, link: string, id: number }[];
}


export interface INavMenuProps {
}

let blurTimeout: NodeJS.Timeout;
export default function NavMenu(props: INavMenuProps) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { changeLayoutHeader, setCategories, state: { categories, callNumber, language } } = useContext<any>(Context);




  const handleProductClick = (title: string): void => {
    clearTimeout(blurTimeout);
    setIsOpen(false);
    changeLayoutHeader({ title });

    blurTimeout = setTimeout(() => {
      setIsOpen(true);
    }, 1000);
  }

  useEffect(() => {
    const getCatalogData = async () => {
      try {
        const catalogReq = await getCategoriesData(language, "children");
        const catalogRes = catalogReq.data;
        const catalogData = catalogRes.data;
        setCategories({ categories: catalogData });
      } catch (error) {
        console.log(error);
      };
    };
    getCatalogData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <div className='nav-menu'>
      <a style={{ color: "inherit", textDecoration: "none" }} href={`tel:${callNumber}`} className="number">{callNumber}</a>
      <ul className="navs">
        <li className='nav-item'>
          <div className='nav-link'>
            <Link to={"/"} >
              {langs[language].Home}
            </Link>
          </div>
        </li>
        <li className='nav-item'>
          <div className='nav-link'>
            <Link onClick={() => handleProductClick("All products")} to={"/categories"} >
            {langs[language]["All products"]}
            </Link>
            <KeyboardArrowDownIcon />
          </div>
          {isOpen && <div className="wide-menu-content">
            {categories.map((item: any) => {
              return (
                <div className="catalog" key={item.id}>
                  <div className="image-wrapper">
                    <img src={looptech.image(item.image)} alt={item.name} />
                  </div>
                  <Link onClick={() => handleProductClick(item.name)} style={{ textDecoration: "none", color: "inherit" }} to={`/categories/${item.slug}`} >
                    <div className="catalog-title">
                      {item.name}
                    </div>
                  </Link>
                  <ul className="catalog-refs">
                    {item?.children?.map((category: any) => {
                      return (
                        <li key={category.id} className="catalog-ref-item">
                          <Link onClick={() => handleProductClick(category.title)} style={{ textDecoration: "none" }} to={`/categories/${category.slug}`}>
                            <span className="catalog-ref-link" >
                              {category.name}
                            </span>
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>}
        </li>
        <li className='nav-item'>
          <div className='nav-link'>
            <Link onClick={() => handleProductClick("Solutions")} to={"/solutions"} >
              {langs[language]["Solutions"]}
            </Link>
          </div>
        </li>
        {/* <li className='nav-item'>
          <div className='nav-link'>
            <Link to={"/"}>
              About us
            </Link>
          </div>
        </li> */}
        <li className='nav-item'>
          <div className='nav-link'>
            <Link to={"/contact"} >
              {langs[language]["Contact"]}

            </Link>
          </div>
        </li>
      </ul>
    </div>
  );
}
