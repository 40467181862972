import styles from "./index.module.css";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Container, List, ListItemButton, ListItemText, Pagination } from "@mui/material";
import relatedStyles from "./Solutions.module.sass"
import { sxProps } from "../Products/Products";
import { Context } from "../../context/mainContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import looptech from "../../helpers/looptech";
import urls from "../../values/urls";
import SolutionsBG from '../../assets/images/solutions-bg.jpg'
import langs from "../../constant/langs";



let debounce: NodeJS.Timeout;
let selectTimeout: NodeJS.Timeout;
let canUseEffect: boolean = true;
const Solutions = () => {
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [solutionsData, setSolutionsData] = useState<any[]>([]);
  const [paginationCount, setPaginationCount] = useState<number>(1);
  const [currPage, setCurrPage] = useState<number>(1);
  const { changeLayoutHeader, setSolutions, state: { solutions, layoutHeader: { title: headerTitle }, language } } = useContext<any>(Context);
  const navigate = useNavigate();
  const currentCategoryId = useRef(-1);
  const { slug } = useParams();

  const getSolutionData = (categoryId: number, page: number = 1) => looptech.api().get(`${urls.solutions(language)}&category=${categoryId}&page=${page}`);



  const getSolutionsData = async (id: number, page: number = 1) => {
    try {
      const resSolutionsData = await getSolutionData(id, page);
      const resData = await resSolutionsData.data;
      const data = resData.data;
      if (id !== currentCategoryId.current) setPaginationCount(data.last_page);
      else currentCategoryId.current = id;
      setCurrPage(page);
      setSolutionsData(data.data);
    } catch (error) {
      console.log(error)
    }
  }


  const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, { id, title, slug }: any) => {
    clearTimeout(selectTimeout);
    e.preventDefault();
    setSelectedCategory(id)
    changeLayoutHeader({ title })
    navigate(`/solutions/${slug}`);
    selectTimeout = setTimeout(() => {
      getSolutionsData(id)
      canUseEffect = false
    }, 300);
  }

  const handlePageChange = (e: React.ChangeEvent<unknown>, value: number) => {
    e?.preventDefault();
    clearTimeout(debounce);
    setCurrPage(value);
    debounce = setTimeout(() => {
      getSolutionsData(selectedCategory, value)
    }, 300);
  }






  useEffect(() => {
    const handleFirst = (data = []) => {
      if (!data.length) return;
      const first: any = data[0]
      setSelectedCategory(first.id);
      getSolutionsData(first.id)
    }

    const getFirst = (data = []) => {
      if (!data.length) return;
      for (let i = 0; i < data.length; i++) {
        const item: any = data[i];
        if (item.slug === slug) {
          setSelectedCategory(item.id);
          return getSolutionsData(item.id);
        }
      }
    };


    if (canUseEffect) {
      if (slug) getFirst(solutions);
      else handleFirst(solutions);
    };
    canUseEffect = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, JSON.stringify(solutions), language]);



  useEffect(() => {
    const getSolutions = async () => {
      try {
        const resSolutions: any = await looptech.api().get(urls.solutionCategories(language));
        const reqData = await resSolutions.data;
        const data = reqData.data;
        setSolutions({ solutions: data });
      } catch (error) {
        console.log(error)
      }
    };

    getSolutions();
  }, [language]);


  const Product = useCallback(({ title, description, slug, image }: any): React.ReactElement => {

    return (
      <Link
        style={{
          color: "inherit",
          textDecoration: "none"
        }}
        to={`/solution/${slug}`}>
        <div className={relatedStyles["products"]}>
          <div className={`${relatedStyles["product"]} ${relatedStyles["simple-product"]}`}>
            <img
              style={{ width: 300, height: 225 }}
              src={looptech.image(image)}
              alt={title}
              id={title}
            />
            <div className={relatedStyles["title"]}>{title}</div>
            <div className={relatedStyles["description"]}>{description}</div>
            {/* <div className={relatedStyles["button-wrapper"]}>
              <CustomButton
                buttonStyles={relatedStyles}
                variant="contained" hoverColor="#cf1b22">
                details
              </CustomButton>
            </div> */}
          </div>
        </div>
      </Link>
    )
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${SolutionsBG})`
        }}
        className={styles.banner}>
        <Container>
          <p
            style={{
              textShadow: "5px 5px 10px black"
            }}
          >{headerTitle || langs[language]['Solutions']}</p>
        </Container>
      </div>

      <div className={relatedStyles["top-products"]}>
        <div className={relatedStyles["solutions-container"]} >
          <List
            className={relatedStyles["category-list"]}
            component="nav" aria-label="main mailbox folders">
            {solutions?.map((item: any) => {
              return (
                <ListItemButton
                  disableRipple
                  key={item.id}
                  className={relatedStyles["category-button"]}
                  selected={selectedCategory === item.id}
                  sx={sxProps}
                  onClick={(e) => handleSelect(e, item)}
                >
                  <ListItemText color="red" primary={item.title} />
                </ListItemButton>
              )
            })}
          </List>
          <div className={relatedStyles["solution-list-container"]} >
            <Container
              className={relatedStyles["solution-list"]}
            >
              {
                solutionsData?.map((item: any) => {
                  return (
                    <Product
                      key={item.id}
                      title={item.title}
                      description={item.description}
                      index={item.id}
                      slug={item.slug}
                      image={item.image}
                    />
                  )
                })
              }
            </Container>
            {!!solutionsData.length && <Pagination
              onChange={handlePageChange}
              page={currPage}
              style={{ marginTop: 30 }}
              className={relatedStyles["pagination"]}
              count={paginationCount}
              variant="outlined" />}
          </div>
        </div >

      </div >
    </>
  );
};

export default Solutions;
