interface ILangs {
  [key: string]: {
    [subKey: string]: string;
  };
}

const langs: ILangs = {
  az: {
    Home: "Əsas səhifə",
    "All products": "Bütün məhsullar",
    Solutions: "Həllər",
    Contact: "Əlaqə",
    "Discover now": "İndi kəşf edin",
    Products: "Məhsullar",
    "Top products": "Ən yaxşı məhsullar",
    "Call us": "Bizə zəng edin",
    "Opening Hours": "İş saatları",
    "Address office": "Ofis ünvanı",
    Category: "Kateqoriya",
    Call: "Zəng",
    Features: "Xüsusiyyətlər",
    Specifications: "Texniki xüsusiyyətlər",
    "Related products": "Əlaqəli məhsullar",
    "Got Any Questions?": "Sualınız var?",
    "Use the form below to get in touch with the sales team":
      "Satış komandası ilə əlaqə qurmaq üçün aşağıdakı formadan istifadə edin",
    Name: "Ad",
    Email: "E-poçt",
    Message: "Mesaj",
    Phones: "Telefonlar",
    "Shop now": "İndi alış-veriş edin",
    Search: "Axtar",
    "Back to home page": "Əsas səhifəyə qayıt",
    "More details": "Ətraflı məlumat",
    "Professional sound systems for arenas":
      "Arenalar üçün professional səs sistemləri",
    "Not Found": "Tapılmadı",

    "Follow us": "Bizi izləyin",
    "Products not found": "Məhsullar tapılmadı",
    Addresses: "Ünvanlar",
    Details: "Ətraflı",
    "Show now": "İndi göstər",
    "Don't hesitate to contact us if you need help":
      "Kömək lazımsa bizimlə əlaqə saxlamaktan çəkinməyin",
    submit: "Göndər",
  },
  en: {
    Home: "Home",
    "All products": "All products",
    Solutions: "Solutions",
    Contact: "Contact",
    "Discover now": "Discover now",
    Products: "Products",
    "Top products": "Top products",
    "Call us": "Call us",
    "Opening Hours": "Opening Hours",
    "Address office": "Address office",
    Category: "Category",
    Call: "Call",
    Features: "Features",
    Specifications: "Specifications",
    "Related products": "Related products",
    "Got Any Questions?": "Got Any Questions?",
    "Use the form below to get in touch with the sales team":
      "Use the form below to get in touch with the sales team",
    Name: "Name",
    Email: "Email",
    Message: "Message",
    Phones: "Phones",
    "Shop now": "Shop now",
    Search: "Search",
    "Back to home page": "Back to home page",
    "More details": "More details",
    "Professional sound systems for arenas":
      "Professional sound systems for arenas",
    "Not Found": "Not Found",

    "Follow us": "Follow us",
    "Products not found": "Products not found",
    Addresses: "Addresses",
    Details: "Details",
    "Show now": "Show now",
    "Don't hesitate to contact us if you need help":
      "Don't hesitate to contact us if you need help",
    submit: "Submit",
  },
  ru: {
    Home: "Главная",
    "All products": "Все продукты",
    Solutions: "Решения",
    Contact: "Контакт",
    "Discover now": "Узнать больше",
    Products: "Продукты",
    "Top products": "Лучшие продукты",
    "Call us": "Позвоните нам",
    "Opening Hours": "Часы работы",
    "Address office": "Адрес офиса",
    Category: "Категория",
    Call: "Позвонить",
    Features: "Характеристики",
    Specifications: "Технические характеристики",
    "Related products": "Связанные продукты",
    "Got Any Questions?": "Есть вопросы?",
    "Use the form below to get in touch with the sales team":
      "Используйте форму ниже, чтобы связаться с отделом продаж",
    Name: "Имя",
    Email: "Эл. почта",
    Message: "Сообщение",
    Phones: "Телефоны",
    "Shop now": "Купить сейчас",
    Search: "Поиск",
    "Back to home page": "Вернуться на главную страницу",
    "More details": "Больше деталей",
    "Professional sound systems for arenas":
      "Профессиональные звуковые системы для арен",
    "Not Found": "Не найдено",

    "Follow us": "Подписывайтесь на нас",
    "Products not found": "Продукты не найдены",
    Addresses: "Адреса",
    Details: "Детали",
    "Show now": "Показать сейчас",
    "Don't hesitate to contact us if you need help":
      "Не стесняйтесь связаться с нами, если вам нужна помощь",
    submit: "Отправить",
  },
};

export default langs;
