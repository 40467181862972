import { useParams } from "react-router-dom";
import RelatedProducts from "../../components/ItemContent/RelatedProducts";
import styles from './Solution.module.sass';
import looptech from "../../helpers/looptech";
import urls from "../../values/urls";
import { useContext, useEffect, useState } from "react";
import { Container } from "@mui/material";
import { Context } from "../../context/mainContext";


const Solution: React.FC = (): React.ReactElement => {
    const [solutionData, setSolutionData] = useState<any>({});
    const { slug } = useParams();
    const { state: { language } } = useContext<any>(Context);


    useEffect(() => {
        const getSolutionData = async () => {
            try {
                const reqData = await looptech.api().get(urls.singleSolution(slug, language));
                const resData = await reqData.data;
                setSolutionData(resData.data);
            } catch (error) {
                console.log(error)
            };
        };
        getSolutionData()
    }, [slug, language])


    return (
        <div className={styles["main-container"]} >
            <Container>
                <p
                    style={{
                        fontWeight: 600,
                        fontSize: 30
                    }}
                >{solutionData.title}</p>
                <div
                    className={styles["body"]}
                    dangerouslySetInnerHTML={{ __html: solutionData.body }}
                />
            </Container>
            <hr />
            <hr style={{ marginTop: 50 }} />
            <RelatedProducts data={solutionData.products} />
        </div>
    )

};


export default Solution;