import * as React from 'react';
import { Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export interface IRightDrawerProps {
    open: boolean;
    onClose: () => void;
}

export default function RightDrawer (props: IRightDrawerProps) {
  return (
    <Drawer
        anchor={'right'}
        open={props.open}
        onClose={props.onClose}
        >
        <div className='right-drawer'>
            <div className='drawer-header'>
                <div className='drawer-header-title'>Shopping Cart</div>
                <IconButton onClick={props.onClose} className='drawer-header-close-button'><CloseIcon /></IconButton>
            </div>
            <hr />
            <div className='drawer-content'>
                <div className='drawer-notf'>No product in this cart.</div>
            </div>
        </div>
    </Drawer>
  );
}
