import { createContext, useReducer } from "react";


interface IProvider {
    children: any
}

const mainContext = (reducer: any, actions: any, defaultValues: any) => {
    const Context = createContext({});

    const Provider = ({ children }: IProvider) => {
        const [state, dispatch] = useReducer(reducer, defaultValues);
        const boundActions: any = {};

        for (let key in actions) {
            boundActions[key] = actions[key](dispatch);
        }

        return (
            <Context.Provider value={{ state, ...boundActions }}>{children}</Context.Provider>
        )
    }
    return { Context, Provider }
};

export default mainContext;