import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Context } from "../../context/mainContext";
import {languagesList} from "../../constant/data";

export default function LanguageChange() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    setLanguage,
    state: {
      language,
    },
  } = React.useContext<any>(Context);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    console.log("event.currentTarget", event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguageHandler = (lng: "az" | "en" | "ru") => {
    if (typeof(Storage) !== "undefined") {
      // Add the value to localStorage with a specific key
      localStorage.setItem("@lang", lng);
    } else {
      console.log("Sorry, your browser does not support Web Storage...");
    }
    setLanguage(lng)
    setAnchorEl(null);
  }

  return (
    <>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpen}
        className="language-change-button"
        endIcon={<KeyboardArrowDown />}
      >
        <span className="flag-text">{languagesList[language]}</span>
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        disableScrollLock
      >
        <MenuItem className="lang-menu-item" onClick={() => changeLanguageHandler("az")}>
          <span className="flag-text">Azerbaijani</span>
        </MenuItem>
        <MenuItem className="lang-menu-item" onClick={() => changeLanguageHandler("en")}>
          <span className="flag-text">English</span>
        </MenuItem>
        <MenuItem className="lang-menu-item" onClick={() => changeLanguageHandler("ru")}>
          <span className="flag-text">Russian</span>
        </MenuItem>
      </Menu>
    </>
  );
}
