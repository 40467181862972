import React, { useMemo } from "react";
import { Button } from "@mui/material";
import homeStyles from "../../pages/Home/ProductPromotion/ProductPromotion.module.sass";
import { FiPhoneCall } from "react-icons/fi"


interface CustomButtonProps {
  children: React.ReactNode;
  startBgColor?: string;
  startTextColor?: string;
  endTextColor?: string;
  buttonStyle?: React.CSSProperties;
  hoverColor?: string;
  variant: "text" | "outlined" | "contained";
  buttonStyles?: any;
  isCall?: boolean;
  onClick?: any
}

const CustomButton = (props: CustomButtonProps) => {
  const {
    children,
    variant,
    startBgColor,
    startTextColor,
    endTextColor,
    hoverColor = "#1dd4df",
    buttonStyles,
    isCall = false,
    onClick
  } = props;
  const buttonStartColor = startBgColor || "black";
  const [isButtonHover, setIsButtonHover] = React.useState<boolean>(false);

  const styles = useMemo(() => buttonStyles || homeStyles, [buttonStyles])



  return (
    <Button
      className={styles["button"]}
      onClick={onClick}
      variant={variant}
      onMouseEnter={() => setIsButtonHover(true)}
      onMouseLeave={() => setIsButtonHover(false)}
      style={{
        color: startTextColor
          ? isButtonHover
            ? endTextColor
            : startTextColor
          : "white",
        background: `linear-gradient(to right,${hoverColor} 50%, ${buttonStartColor} 50%)`,
        backgroundSize: "200% 100%",
        backgroundPosition: isButtonHover ? "left bottom" : "right bottom",
        transition: "all 0.5s ease-out",
        ...props.buttonStyle,
      }}
    >
      {isCall && <FiPhoneCall style={{ marginRight: 15 }} size={20} color={isButtonHover ? endTextColor : startBgColor} />}
      {children}
    </Button>
  );
};

export default CustomButton;
