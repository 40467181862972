import {
  Collapse,
  Container,
  List,
  ListItemButton,
  ListItemText,
  Pagination,
  SxProps,
  easing,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "../Solutions/index.module.css";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import productStyles from "./Products.module.sass";
import CustomButton from "../../components/UI/CustomButton";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../context/mainContext";
import urls from "../../values/urls";
import looptech from "../../helpers/looptech";
import ProductBG from "../../assets/images/product-bg.jpg";
import langs from "../../constant/langs";

// item button interface
export type ICategoryItem = {
  id: number;
  title: string;
  image?: string;
  link?: string;
};

export interface IItemButton {
  buttonText: string;
  onCategoryClick?: any;
  onSubCategoryClick?: any;
  children?: React.ReactElement;
  itemId: number;
  isCollapsible: boolean;
  collapsedItems: any;
  active: boolean;
  selected?: number;
  slug?: string;
}

export interface ICollapsedItem {
  isCollapsed: boolean;
  onClick: any;
  selected?: number;
  collapsedItems: ICategoryItem[];
}

export interface IProduct {
  img: string;
  title: string;
  description: string;
  id: number;
}

export const sxProps: SxProps = {
  bgcolor: "#eee",
  "&.Mui-selected": {
    color: "white",
    backgroundColor: "black",
  },
  "&.Mui-selected:hover": {
    color: "white",
    backgroundColor: "black",
  },
  ":hover": {
    color: "black",
    backgroundColor: "#ccc",
  },
};

let debounce: NodeJS.Timeout;
let controller: AbortController;
let selectTimeout: NodeJS.Timeout;
let canUseEffect: boolean = true;
const Products: React.FC = (): React.ReactElement => {
  const {
    changeLayoutHeader,
    state: {
      categories,
      language,
      layoutHeader: { title: headerTitle },
    },
  } = useContext<any>(Context);

  const [productList, setProducts] = useState([]);
  const [paginationCount, setPaginationCount] = useState(1);
  const [open, setOpen] = useState<number>(-1);
  const [selected, setSelected] = useState<number>(-1);
  const [currPage, setCurrPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const savedProductParams = useRef<any>(null);

  const currentCategoryId = useRef(-1);
  const isFirst = useRef(true);

  const { product } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();

  const getProducts = (id: number, page: number = 1, signal: AbortSignal) =>
    looptech.api(signal).get(urls.categoryProducts(id, page, language));

  const getProductData = async (
    id: number,
    page: number = 1,
    search: string = ""
  ) => {
    setLoading(true);
    controller = new AbortController();
    const signal = controller.signal;
    try {
      let reqProduct;
      if (!search) reqProduct = await getProducts(id, page, signal);
      else
        reqProduct = await looptech
          .api()
          .get(urls.productSearch(search, page, language));
      const res = await reqProduct.data;
      const resData = res.data;
      if (id !== currentCategoryId.current)
        setPaginationCount(resData.last_page);
      else if (search) setPaginationCount(resData.last_page);
      else currentCategoryId.current = id;
      setCurrPage(page);
      setProducts(resData.data);
    } catch (error) {
      console.log(error);
    } finally {
      savedProductParams.current = { id, page, search };
      setLoading(false);
    }
  };

  const findElementById = (id: string): HTMLElement | null =>
    document.getElementById(id);
  const scrollToNode = (
    node: HTMLElement | null,
    options: ScrollIntoViewOptions = { behavior: "smooth", block: "center" }
  ): void => node?.scrollIntoView(options);

  useEffect(() => {
    const params = savedProductParams.current;
    if(params){
        getProductData(params.id, params.page, params.search);
    }
  }, [language]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const searchQuery = query.get("search") || "";
    if (searchQuery) {
      setSearchText(searchQuery);
      getProductData(-1, 1, searchQuery);
    }

    const handleSlug = (item: any) => {
      if (item) isFirst.current = false;
      if (!item) {
        if (categories.length) {
          const firstCategory = categories[0];
          const firstCategoryId = firstCategory.id;
          setOpen(firstCategoryId);
          setSelected(firstCategoryId);
          getProductData(firstCategoryId, 1);
        }
        return;
      }
      const itemId = item.id;
      setOpen(item.parent_id || itemId);
      setSelected(itemId);
      setTimeout(() => {
        const element = findElementById(itemId);
        scrollToNode(element);
      }, 500);
      if (item) getProductData(itemId, 1);
    };

    const handleParam = (param: string | undefined, categories: any[]) => {
      if (param) {
        for (let i = 0; i < categories?.length; i++) {
          const category = categories[i];
          if (category.slug === param) return handleSlug(category);

          const children = category.children;
          for (let j = 0; j < children?.length; j++) {
            const child = children[j];
            if (child.slug === param) return handleSlug(child);
          }
        }
      }
      return handleSlug(null);
    };
    if (!searchQuery) {
      canUseEffect && handleParam(product, categories);
      canUseEffect = true;
    }
    return controller?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, JSON.stringify(categories), search]);

  const changeHeader = (title: string, bgImage?: string): void => {
    changeLayoutHeader({ title, bgImage });
  };

  const handleOpen = (itemId: number) => {
    setOpen((prev: number) => {
      if (prev === itemId) return -1;
      return itemId;
    });
  };

  const handleSelected = (
    itemId: number,
    title: string,
    slug: string,
    isParent: boolean = false
  ) => {
    clearTimeout(selectTimeout);
    if (selected !== itemId) setSelected(itemId);
    if (isParent && open !== itemId) setOpen(itemId);
    selectTimeout = setTimeout(() => {
      navigate(`/categories/${slug}`);
      changeHeader(title);
      getProductData(itemId, 1);
      canUseEffect = false;
    }, 300);
  };

  const handleExpand = (e: React.SyntheticEvent, id: number) => {
    e.stopPropagation();
    handleOpen(id);
  };

  const CollapsedItem = useCallback(
    ({ isCollapsed = false, onClick, collapsedItems, selected }: any) => {
      return (
        <Collapse
          in={isCollapsed}
          easing={easing.easeInOut}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {collapsedItems?.map(({ name, id, slug }: any) => {
              return (
                <ListItemButton
                  key={id}
                  id={`${id}`}
                  disableRipple
                  selected={selected === id}
                  className={productStyles["category-button"]}
                  onClick={() => onClick(id, name, slug)}
                  sx={{ pl: 4, ...sxProps }}
                >
                  <ListItemText primary={name} />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      );
    },
    []
  );

  const ItemButton = useCallback(
    ({
      buttonText,
      onCategoryClick,
      onSubCategoryClick,
      isCollapsible,
      slug,
      collapsedItems,
      itemId,
      active,
      selected,
    }: IItemButton): React.ReactElement => {
      return (
        <>
          <ListItemButton
            disableRipple
            id={`${itemId}`}
            selected={selected === itemId}
            className={productStyles["category-button"]}
            onClick={() => onCategoryClick(buttonText, slug)}
            sx={{ pl: 2, ...sxProps }}
          >
            <ListItemText primary={buttonText} />
            {active ? (
              <ExpandLess onClick={(e) => handleExpand(e, itemId)} />
            ) : (
              <ExpandMore onClick={(e) => handleExpand(e, itemId)} />
            )}
          </ListItemButton>
          {isCollapsible && (
            <CollapsedItem
              isCollapsed={active}
              selected={selected}
              onClick={(id: string, name: string, childrenSlug: string) =>
                onSubCategoryClick(id, name, childrenSlug)
              }
              collapsedItems={collapsedItems}
            />
          )}
        </>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const Product = useCallback(
    ({ image, title, description, slug }: any): React.ReactElement => {
      return (
        <Link
          to={`/products/${slug}`}
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <div className={productStyles["simple-product"]}>
            <div
              style={{
                border: "1px solid #aaaaaa",
                marginBottom: 10,
                backgroundColor: "white",
              }}
              className={productStyles["image-wrapper"]}
            >
              <img src={looptech.image(image)} alt="" />
            </div>
            <div className={productStyles["title"]}>{title}</div>
            <div className={productStyles["description"]}>{description}</div>
            <div className={productStyles["button-wrapper"]}>
              <Link to={`/products/${slug}`}>
                <CustomButton
                  buttonStyles={productStyles}
                  variant="contained"
                  hoverColor="#cf1b22"
                >
                  {langs[language]["Show now"]}
                </CustomButton>
              </Link>
            </div>
          </div>
        </Link>
      );
    },
    [language]
  );

  const handlePageChange = (e: React.ChangeEvent<unknown>, value: number) => {
    e?.preventDefault();
    clearTimeout(debounce);
    setCurrPage(value);
    debounce = setTimeout(() => {
      const id = searchText.length ? -1 : selected;
      getProductData(id, value, searchText);
    }, 300);
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${ProductBG})`,
        }}
        className={styles.banner}
      >
        <Container>
          <p
            style={{
              textShadow: "5px 5px 10px black",
            }}
          >
            {headerTitle || langs[language]["All products"]}
          </p>
        </Container>
      </div>
      <div className={productStyles["general"]}>
        {!!searchText.length || (
          <List
            className={productStyles["products-category-list"]}
            component="nav"
            aria-labelledby="nested-list-subheader"
            disablePadding
          >
            {categories?.map((item: any) => {
              const itemId = item.id;
              return (
                <ItemButton
                  key={itemId}
                  onCategoryClick={(name: string, slug: string) =>
                    handleSelected(item.id, name, slug, true)
                  }
                  onSubCategoryClick={(
                    id: number,
                    name: string,
                    slug: string
                  ) => handleSelected(id, name, slug)}
                  buttonText={item.name}
                  isCollapsible={true}
                  itemId={itemId}
                  slug={item.slug}
                  active={open === itemId}
                  selected={selected}
                  collapsedItems={item.children}
                />
              );
            })}
          </List>
        )}

        <div className={productStyles["product-container"]}>
          <Container className={productStyles["products-list"]}>
            {productList.map((item: any) => {
              return (
                <Product
                  key={item.id}
                  image={item.image}
                  title={item.title}
                  description={item.description}
                  slug={item.slug}
                />
              );
            })}
          </Container>
          {searchText.length > 0 && !loading && (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
              }}
            >
              {productList.length === 0 &&
                langs[language]["Products not found"] + "!"}
            </p>
          )}
          {!!productList.length && (
            <Pagination
              onChange={handlePageChange}
              style={{ marginTop: 30 }}
              boundaryCount={1}
              siblingCount={1}
              page={currPage}
              className={productStyles["pagination"]}
              count={paginationCount}
              variant="outlined"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Products;
