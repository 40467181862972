import * as React from 'react';
import './Navbar.sass';
import { Container, IconButton } from '@mui/material';
import LanguageChange from './LanguageChange';
// import logo
import logoImage from '../../assets/itc-logo-black.png';
import HeaderButtons from './HeaderButtons';
import HeaderSearch from './HeaderSearch';
import RightDrawer from './RightDrawer';
import NavMenu from './NavMenu';
import MenuIcon from '@mui/icons-material/Menu';
import LeftDrawer from './LeftDrawer';
import { Link } from 'react-router-dom';

export interface INavbarProps {
}

export default function Navbar(props: INavbarProps) {
  const [searchBarOpened, setSearchBarOpened] = React.useState<boolean>(false);
  const [rightDarwerOpened, setRightDriwerOpened] = React.useState<boolean>(false);
  const [leftDrawerOpened, setLeftDrawerOpened] = React.useState<boolean>(false);


  return (
    <>
      <div className='navbar'>
        <Container>
          <header>
            <div className='left-buttons'>
              <IconButton className='left-drawer-open-button' onClick={() => setLeftDrawerOpened(true)}><MenuIcon /></IconButton>
              <LanguageChange />
            </div>
            <div>
              <Link to={'/'} >
                <img src={logoImage} alt="logo" width="100" />
              </Link>
            </div>
            <div><HeaderButtons openSearch={() => setSearchBarOpened(true)} openRightDrawer={() => setRightDriwerOpened(true)} /></div>
            <HeaderSearch open={searchBarOpened} closeSearch={() => setSearchBarOpened(false)} />
          </header>
        </Container>
        <hr />
        <Container>
          <NavMenu />
        </Container>
        <hr />
      </div>
      <LeftDrawer open={leftDrawerOpened} drawerToggle={setLeftDrawerOpened} onClose={() => setLeftDrawerOpened(false)} />
      <RightDrawer open={rightDarwerOpened} onClose={() => setRightDriwerOpened(false)} />
    </>
  );
}
