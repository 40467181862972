import { Link } from "react-router-dom";
import logo from "../../assets/itc-logo-black.png"
import CustomButton from "../../components/UI/CustomButton";
import langs from "../../constant/langs";
import { useContext } from "react";
import { Context } from "../../context/mainContext";

const NotFound = () => {
    const { state: { language } } = useContext<any>(Context);

    return (
        <div style={{ height: 400, marginTop: 100, flexWrap: "wrap", width: "100%", textAlign: 'center' }} >
            <img style={{ height: 100 }} alt="logo" src={logo} />
            <h1 >404</h1>
            <h3>{langs[language]["Not Found"]}</h3>
            <Link to="/" >
                <CustomButton
                    variant="contained"
                    hoverColor="white"
                    endTextColor="black"
                    startTextColor="white"
                >
                    {langs[language]["Back to home page"]}
                </CustomButton>
            </Link>
        </div>
    )
}

export default NotFound;