import React from "react";
import createDataContext from "./createDataContext";
import { IInitialLayoutHeader } from "./models";




const initialLayoutHeader: IInitialLayoutHeader = {
    title: "",
    bgImage: "",
}

interface IAction {
    payload: any
    type: string
}

const mainReducer = (state: any, action: IAction) => {
    switch (action.type) {
        case "change_header":
            return { ...state, layoutHeader: { title: action.payload.title, bgImage: action.payload.bgImage } };
        case "set_categories":
            return { ...state, categories: action.payload };
        case "set_solutions":
            return { ...state, solutions: action.payload };
        case "set_number":
            return { ...state, callNumber: action.payload };
        case "set_contacts":
            return { ...state, contacts: action.payload };
        case "set_social":
            return { ...state, social: action.payload };
        case "set_language":
            return { ...state, language: action.payload };
    };
};


// layout
const changeLayoutHeader = (dispatch: React.Dispatch<any>) => {
    return ({ title, bgImage }: IInitialLayoutHeader) => {
        dispatch({ type: "change_header", payload: { title, bgImage } });
    }
};

//categories
const setCategories = (dispatch: React.Dispatch<any>) => {
    return ({ categories }: any) => {
        dispatch({ type: "set_categories", payload: categories })
    }
};

//solutions
const setSolutions = (dispatch: React.Dispatch<any>) => {
    return ({ solutions }: any) => {
        dispatch({ type: "set_solutions", payload: solutions });
    }
};

// call
const setCallNumber = (dispatch: React.Dispatch<any>) => {
    return ({ number }: { number: string }) => {
        dispatch({ type: "set_number", payload: number });
    }
};


// contacts
const setContact = (dispatch: React.Dispatch<any>) => {
    return ({ data }: any) => {
        dispatch({ type: "set_contacts", payload: data });
    }
};

// social
const setSocial = (dispatch: React.Dispatch<any>) => {
    return ({ data }: any) => {
        dispatch({ type: "set_social", payload: data });
    }
}

// language
const setLanguage = (dispatch: React.Dispatch<any>) => {
    return (lang: string) => {
        dispatch({ type: "set_language", payload: lang });
    }
}

export const { Provider, Context } = createDataContext(
    mainReducer,
    {
        changeLayoutHeader,
        setCategories,
        setSolutions,
        setCallNumber,
        setContact,
        setSocial,
        setLanguage,
    },
    {
        categories: [],
        solutions: [],
        layoutHeader: initialLayoutHeader,
        callNumber: "",
        contacts: {},
        social: {},
        language: "en"
    }
);