import Layout from "./HOC/Layout/Layout";
import ScrollToTop from "./components/ScrollToTop";
import { Provider } from "./context/mainContext";
import { createTheme, ThemeProvider } from "@mui/material";
import Root from "./root/Root";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
});

function App() {
  return (
    <div className="App">
      <Provider>
        <ThemeProvider theme={theme}>
          <Layout>
            <ScrollToTop />
            <Root />
          </Layout>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
