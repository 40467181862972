import * as React from 'react';
import { IconButton, Stack } from '@mui/material';
// import search, bascet and user icons from mui
import SearchIcon from '@mui/icons-material/Search';


export interface IHeaderButtonsProps {
  openSearch: () => void;
  openRightDrawer: () => void;
}

export default function HeaderButtons (props: IHeaderButtonsProps) {
  return (
    <Stack direction="row" className='button-list'>
        <IconButton className='search-button' onClick={props.openSearch}><SearchIcon className="header-icon" /></IconButton>
        {/* <IconButton className='user-button'><PersonOutlineOutlined className="header-icon" /></IconButton>
        <IconButton className='bascet-button' onClick={props.openRightDrawer}><ShoppingBagOutlinedIcon className="header-icon" /></IconButton> */}
    </Stack>
  );
}
