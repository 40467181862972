import { ILanguage } from "../constant/types";

const urls = {
    heroBannerUrl: (lng: ILanguage) => `/hero-sliders?lang=${lng}`,
    mainProductsUrl: (lng: ILanguage) => `/products?lang=${lng}`,
    mainCategoriesUrl: (lng: ILanguage) => `/categories?lang=${lng}`,
    solutionCategories: (lng: ILanguage) => `/solution-categories?lang=${lng}`,
    solutions: (lng: ILanguage) => `/solutions?lang=${lng}`,
    contact: (lng: ILanguage) => `/contact?lang=${lng}`,
    callPhone: "/call-phone",
    social: (lng: ILanguage) => `/social?lang=${lng}`,
    singleProduct: (slug: string | undefined, lng: ILanguage) => ({
        product: `/products/${slug}?lang=${lng}`,
        related: `/products/${slug}/related?lang=${lng}`
    }),
    singleCategory: (slug: string, lng: ILanguage) => `/categories/${slug}?lang=${lng}`,
    categoryProducts: function (categoryId: number, page: number = 1, lng: ILanguage) {
        return `${this.mainProductsUrl(lng)}&category=${categoryId}&page=${page}`
    },
    singleSolution: (slug: string | undefined, lng: ILanguage) => `/solutions/${slug}?lang=${lng}`,
    productSearch: function (search: string, page: number = 1, lng: ILanguage) {
        return `${this.mainProductsUrl(lng)}&search=${search}&page=${page}`;
    }
}

export default urls;